.section-header{
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.section-header h3{
    margin-bottom: unset;
}

.show-hide-button{
    margin-left: 10px;
}

.audit-table{
    display: table;
    border-collapse: collapse;
    margin: 15px 0px
}

.audit-table-header{
    background-color: rgba(0,0,0,0.1)
}

.audit-table-cell{
    padding: 3px;
    border: solid 1px rgba(0,0,0,0.1);
}

.audit-table-span{
    margin-bottom: unset;
    display: block;
    padding: 4px 10px;
    border-radius: 5px;
}

.repsonse:hover{
    background-color: rgba(0, 110, 255, 0.1);
}

.audit-member-name-anchor {
    color: inherit;
    font-weight: 600;
}

.audit-button-show-hide{
    background-color: transparent;
    min-width: 60px;
    margin-left: 6px;
    border-radius: 4px;
    border: solid 2px rgb(18, 102, 186);
    color: rgb(18, 102, 186);
    transition: ease-in-out .12s;
}

.audit-button-show-hide:hover{
    background-color: rgb(18, 102, 186);
    color: white;
}

.audit-bullet-indent{
    padding-left: 15px;
}