body {
  margin: 2rem;
}

td {
  padding: 0.5rem;
}

:target {
  background-color: yellow;
}
