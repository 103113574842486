.form-container{
    padding: 20px; 
    margin: 20px 0px;
    border: solid 1px grey;
    display: table;
}

.form-input{
    margin-right: 20px;
}

.form-span{
    width: 160px;
}

.age-banded-input{
    width: 120px;
}

.table-column-header{
    font-weight: 700;
}

.table-columns{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.table-th{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    margin-bottom: 10px;
    background-color: white;
    border-bottom: solid 1px #dee2e6;
}

.warning-span{
    margin-top: 15px;
    font-weight: 700;
    color: white;
    background-color: crimson;
    border: solid 4px lightcoral;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    flex-wrap: nowrap;
}
