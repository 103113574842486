.flexContainer {
    display: flex;
}

.flexContainer > div {
    margin: 0.5em;
    background-color: rgb(225, 223, 223);
    border-radius: 5px;
    padding: 1em;
    border: 1px solid rgb(188, 187, 187);
}

.flexContainer > div:first-child {
    margin-left: 0em;
}

.fieldErrors {
    padding: 1em;
    margin: 1em 0;
    width: fit-content;
    background-color: rgb(188, 187, 187);
    border: 1px solid red;
    border-radius: 5px;
    color: black;
}

.errorName:not(:last-child) {
    margin-right: 2px;
    
}
